import axios from "axios";
import useAuth from "@/modules/auth/composables/useAuth";

const axidentApi = axios.create({
  baseURL: process.env.VUE_APP_AXIDENT_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axidentApi.interceptors.request.use((config) => {
  const { authToken } = useAuth();
  config.headers = { Authorization: `Bearer ${authToken.value}` };
  return config;
});

export default axidentApi;
