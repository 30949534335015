export default () => ({
  countries: [],
  contracts: [],
  seniorities: [],
  documents: [],
  // searchTypes: [] technical debt, the back should bring the correct options
  searchTypes: [
    {
      description: "The user's identification document.",
      enabled: true,
      id: "ID_DOCUMENT",
      label: "Document Identifier",
      parent: null,
    },
    {
      description: "The user's name.",
      enabled: true,
      id: "NAME",
      label: "Name",
      parent: null,
    },
    {
      description: "The user's first surname.",
      enabled: true,
      id: "FIRST_SURNAME",
      label: "firstSurname",
      parent: null,
    },
    {
      description: "The user's last surname.",
      enabled: true,
      id: "LAST_SURNAME",
      label: "lastSurname",
      parent: null,
    },
    {
      description: "The user's Human's resource identifier.",
      enabled: true,
      id: "HR_IDENTIFIER",
      label: "HR Identifier (SAP)",
      parent: null,
    },
    {
      description: "The user's email.",
      enabled: true,
      id: "EMAIL",
      label: "Email",
      parent: null,
    },
  ],
  places: [],
  usualWorkCenterType: [],
  usualWorkCenterZone: [],
  severities: [],
  inItinereOpts: [],
  workedHours: [],
  activities: [],
  causes: [],
  workTypes: [],
  basicCauses: [],
  autonomousCommunities: [],
  regions: [],
  medicalReportTypes: [],
  medicalReportCauseTypes: [],
  medicalReportForecastTypes: [],
  medicalReportContactWayTypes: [],
  medicalReportInjuriedPartTypes: [],
  medicalReportStandardInjuryDescriptionTypes: [],
  siteAdditionalInfo: [],
  pagination: {},
  errorMsg: "",
  isLoading: false,
});
