// export const myAction = async ({commit}) => {}
import axidentApi from "../../api/axidentApi";
import { masterTypesId } from "@/enums";
import { handleTokenExpired } from "../../helpers/handleTokenExpired";

export const setContractsFilter = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: contracts, pagination },
    } = await axidentApi.get(
      `/master-types/${masterTypesId.contractType}/master-type-values?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setContractsFilter", contracts);
    commit("setPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const setSenioritiesFilter = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: seniorities, pagination },
    } = await axidentApi.get(
      `/master-types/${masterTypesId.jobSeniority}/master-type-values?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setSenioritiesFilter", seniorities);
    commit("setPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const setDocumentsFilter = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: documents, pagination },
    } = await axidentApi.get(
      `/master-types/${masterTypesId.identificationDocumentType}/master-type-values?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setDocumentsFilter", documents);
    commit("setPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const setUsualWorkCenterType = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: usualWorkCenterType, pagination },
    } = await axidentApi.get(
      `/master-types/${masterTypesId.usualWorkCenterType}/master-type-values?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setUsualWorkCenterType", usualWorkCenterType);
    commit("setPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const setUsualWorkCenterZone = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: usualWorkCenterZone, pagination },
    } = await axidentApi.get(
      `/master-types/${masterTypesId.usualWorkCenterZone}/master-type-values?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setUsualWorkCenterZone", usualWorkCenterZone);
    commit("setPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const setSeverities = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: severities, pagination },
    } = await axidentApi.get(
      `/master-types/${masterTypesId.accidentSeverityType}/master-type-values?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setSeverities", severities);
    commit("setPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const setInItinereTypes = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: inItinereOpts, pagination },
    } = await axidentApi.get(
      `/master-types/${masterTypesId.inItineraryType}/master-type-values?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setInItinereTypes", inItinereOpts);
    commit("setPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const setWorkedHours = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: workedHours, pagination },
    } = await axidentApi.get(
      `/master-types/${masterTypesId.workedHours}/master-type-values?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setWorkedHours", workedHours);
    commit("setPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const setActivities = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: activities, pagination },
    } = await axidentApi.get(
      `/master-types/${masterTypesId.activityWasDoing}/master-type-values?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setActivities", activities);
    commit("setPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const setAccidentPlaces = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: places, pagination },
    } = await axidentApi.get(
      `/master-types/${masterTypesId.accidentPlace}/master-type-values?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setAccidentPlaces", places);
    commit("setPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const setCauses = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: causes, pagination },
    } = await axidentApi.get(
      `/master-types/${masterTypesId.accidentCause}/master-type-values?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setCauses", causes);
    commit("setPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const setWorkTypes = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: workTypes, pagination },
    } = await axidentApi.get(
      `/master-types/${masterTypesId.accidentWorkType}/master-type-values?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setWorkTypes", workTypes);
    commit("setPagination", pagination);
  } catch (error) {
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const setBasicCauses = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: basicCauses, pagination },
    } = await axidentApi.get(
      `/master-types/${masterTypesId.basicCause}/master-type-values?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setBasicCauses", basicCauses);
    commit("setPagination", pagination);
  } catch (error) {
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const setCountriesFilter = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: countries, pagination },
    } = await axidentApi.get(
      `/master-types/${masterTypesId.country}/master-type-values?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setCountriesFilter", countries);
    commit("setPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const setSearchTypesFilters = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: types, pagination },
    } = await axidentApi.get(
      `/master-types/${masterTypesId.employeeSearchType}/master-type-values?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setSearchTypesFilters", types);
    commit("setPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const setAutonomousCommunities = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: autonomousCommunities, pagination },
    } = await axidentApi.get(
      `/master-types/${masterTypesId.autonomousCommunity}/master-type-values?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setAutonomousCommunities", autonomousCommunities);
    commit("setPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const setRrhhRegions = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: regions, pagination },
    } = await axidentApi.get(
      `/master-types/${masterTypesId.rrhh}/master-type-values?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setRrhhRegions", regions);
    commit("setPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const setSiteAdditionalInfo = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: aditionalInfo, pagination },
    } = await axidentApi.get(
      `/master-types/${masterTypesId.siteAdditionalInfo}/master-type-values?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setSiteAdditionalInfo", aditionalInfo);
    commit("setPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const addNewRegion = async ({ commit }, region) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    await axidentApi.post(
      `/master-types/${masterTypesId.rrhh}/master-type-values`,
      region
    );
    commit("addNewRegion", region);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const fetchMedicalReportTypes = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data, pagination },
    } = await axidentApi.get(
      `/master-types/${masterTypesId.medicalReportType}/master-type-values?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setMedicalReportTypes", data);
    commit("setPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const fetchMedicalReportCauseTypes = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data, pagination },
    } = await axidentApi.get(
      `/master-types/${masterTypesId.medicalReportCauseType}/master-type-values?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setMedicalReportCauseTypes", data);
    commit("setPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const fetchMedicalReportForecastTypes = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data, pagination },
    } = await axidentApi.get(
      `/master-types/${masterTypesId.medicalReportForecastType}/master-type-values?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setMedicalReportForecastTypes", data);
    commit("setPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const fetchMedicalReportContactWayTypes = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data, pagination },
    } = await axidentApi.get(
      `/master-types/${masterTypesId.medicalReportContactWayType}/master-type-values?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setMedicalReportContactWayTypes", data);
    commit("setPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const fetchMedicalReportInjuriedPartTypes = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data, pagination },
    } = await axidentApi.get(
      `/master-types/${masterTypesId.medicalReportInjuriedPartType}/master-type-values?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setMedicalReportInjuriedPartTypes", data);
    commit("setPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const fetchMedicalReporStandardInjuryDescriptionTypes = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data, pagination },
    } = await axidentApi.get(
      `/master-types/${masterTypesId.medicalReportStandardInjuryDescriptionType}/master-type-values?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setMedicalReportStandardInjuryDescriptionTypes", data);
    commit("setPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};
