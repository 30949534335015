// export const myGetter = (state) => { return state.something }

export const getJobs = (state) => state.jobs;

export const getJob = (state) => state.job;

export const getPagination = (state) => state.pagination;

export const getErrorMessage = (state) => state.errorMsg?.response?.data?.code;

export const getLoadingState = (state) => state.isLoading;

export const filteredJobs = (state) => (searchTerm, keys) => {
  return state.jobs.filter((job) =>
    keys.some((key) =>
      String(job[key]).toLowerCase().includes(searchTerm.trim().toLowerCase())
    )
  );
};
