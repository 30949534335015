import jwtDecode from "jwt-decode";

export const getSavedState = (key) => {
  return localStorage.getItem(key);
};

export const saveState = (key, payload) => {
  localStorage.setItem(key, payload);
};

export const checkTokenDate = (accessToken) => {
  const decoded = jwtDecode(accessToken);
  return decoded.exp < Math.round(Date.now() / 1000);
};

export const userToCreate = (user) => {
  const { uid, mail, familyName, givenName, jobname, sn, hrid } = user;
  const userToSave = {
    uid: uid,
    name: givenName.toUpperCase(),
    firstSurname: familyName.toUpperCase(),
    email: mail,
    customAdditionalInfo: {
      hrId: hrid,
      lastSurname: sn.toUpperCase(),
    },
    job: {
      id: jobname,
    },
  };

  return userToSave;
};

export const userToLogout = (user) => {
  const pi_sri = user["pi.sri"];
  const userToRevokeSession = {
    piSri: pi_sri,
  };

  return userToRevokeSession;
};
