// export const myAction = async ({commit}) => {}
import axidentApi from "../../api/axidentApi";
import { handleTokenExpired } from "../../helpers/handleTokenExpired";

// Corresponding with AXIDENTPLUS
export const getSites = async (
  { commit },
  { selectedPage = 0, pageSize = 1000 }
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: sites, pagination },
    } = await axidentApi.get(
      `/sites?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setSites", sites);
    commit("setSitesPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

// Corresponding with MASTERDATA
export const getStores = async (
  { commit },
  { selectedPage = 0, pageSize = 1000 }
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: sites, pagination },
    } = await axidentApi.get(
      `/stores?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setStores", sites);
    commit("setStoresPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const getSite = async ({ commit }, id) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const { data } = await axidentApi.get(`/sites/${id}`);
    commit("setSite", data);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const getStore = async ({ commit }, id) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const { data } = await axidentApi.get(`/stores/${id}`);
    commit("setStore", data);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const addNewSite = async ({ commit }, site) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    await axidentApi.post(`/sites`, site);
    commit("addNewSite", site);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const updateRrhhRegion = async ({ commit, state }, payload) => {
  const { data: siteData, newValue } = payload;
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const { data } = await axidentApi.post(`/sites`, {
      ...siteData,
      customAdditionalInfo: {
        rrhhRegion: newValue,
        costCenter: siteData.customAdditionalInfo.costCenter,
        autonomousCommunity: siteData.customAdditionalInfo.autonomousCommunity,
      },
    });

    // Create new array with the updated values
    const updatedSites = state.sites.map((site) => {
      if (site.id === data.id) {
        return data;
      }
      return site;
    });
    // Commit the updated state to vuex to update the view
    commit("setSites", updatedSites);
    commit("setSite", data);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const updateSite = async ({ commit, state }, payload) => {
  const { siteData, newValues } = payload;
  const mappedPayload = {
    ...newValues,
    customAdditionalInfo: {
      rrhhRegion: siteData.customAdditionalInfo.rrhhRegion,
      costCenter: newValues.customAdditionalInfo.costCenter,
      autonomousCommunity: newValues.customAdditionalInfo.autonomousCommunity,
    },
  };

  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const { data } = await axidentApi.post(`/sites`, mappedPayload);

    // Create new array with the updated values
    const updatedSites = state.sites.map((site) => {
      if (site.id === data.id) {
        return data;
      }
      return site;
    });
    // Commit the updated state to vuex to update the view
    commit("setSites", updatedSites);
    commit("setSite", data);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};
