import store from "@/store";
import { siteRoles } from "@/modules/shared/composables/useRoles";

const isAdminGuard = async (to, from, next) => {
  const { allowedRoles } = to.meta;

  let role;
  if (localStorage.getItem("role")) {
    role = localStorage.getItem("role");
  } else {
    role = store.getters["auth/getRole"];
    localStorage.setItem("role", role);
  }

  const isAdmin = (role) => siteRoles.admin.includes(role);
  const isTechcnical = (role) => siteRoles.technical.includes(role);

  if (allowedRoles && (isAdmin(role) || isTechcnical(role))) {
    next();
  } else {
    next({ path: "/home" });
  }
};

export const isHrGuard = async (to, from, next) => {
  const { allowedRoles } = to.meta;

  let role;
  if (localStorage.getItem("role")) {
    role = localStorage.getItem("role");
  } else {
    role = store.getters["auth/getRole"];
    localStorage.setItem("role", role);
  }

  const isAdmin = (role) => siteRoles.admin.includes(role);

  if (allowedRoles && isAdmin(role)) {
    next();
  } else {
    next({ path: "/home" });
  }
};

export const isAllowed = async (to, from, next) => {
  const { allowedRoles } = to.meta;

  let role;
  if (localStorage.getItem("role")) {
    role = localStorage.getItem("role");
  } else {
    role = store.getters["auth/getRole"];
    localStorage.setItem("role", role);
  }

  const isAdmin = (role) => siteRoles.admin.includes(role);
  const isManager = (role) => siteRoles.manager.includes(role);
  const isTechcnical = (role) => siteRoles.technical.includes(role);

  if (
    allowedRoles &&
    (isAdmin(role) || isManager(role) || isTechcnical(role))
  ) {
    next();
  } else {
    next({ path: "/home" });
  }
};

export default isAdminGuard;
