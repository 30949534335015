// export const myGetter = (state) => { return state.something }

export const getIncidents = (state) =>
  state.incidents.sort(
    (a, b) => new Date(b.occurrenceDate) - new Date(a.occurrenceDate)
  );

export const getIncident = (state) => state.incident;

export const filteredIncidents = (state) => (searchTerm, keys) => {
  return state.incidents
    .filter((incident) =>
      keys.some((key) =>
        String(incident[key])
          .toLowerCase()
          .includes(searchTerm.trim().toLowerCase())
      )
    )
    .sort((a, b) => new Date(b.occurrenceDate) - new Date(a.occurrenceDate));
};

export const getIncidentById = (state) => (id) => {
  return state.incidents.find((incident) => incident.id === id);
};

export const getPagination = (state) => state.pagination;

export const getErrorCode = (state) => state.errorMsg?.response?.data?.code;

export const getErrorMessage = (state) =>
  state.errorMsg?.response?.data?.description;

export const getLoadingState = (state) => state.isLoading;
