// export const myAction = async ({commit}) => {}

import axidentApi from "../../api/axidentApi";
import { accidentStatus, eventType } from "@/enums";
import { handleTokenExpired } from "../../helpers/handleTokenExpired";

export const setIncidents = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: incidents, pagination },
    } = await axidentApi.get(
      `/accidents?type=${eventType.incident}&status=${accidentStatus.closed}&selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setIncidents", incidents);
    commit("setPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const updateIncident = async ({ commit, state }, payload) => {
  const { id, data } = payload;
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const resp = await axidentApi.patch(`/accidents/${id}`, data);

    // Create new array with the updated values
    const updatedIncidents = state.incidents.map((incident) => {
      if (incident.id === resp.id) {
        return resp;
      }
      return incident;
    });
    // Commit the updated state to vuex to update the view
    commit("setIncidents", updatedIncidents);
    commit("setIncident", resp.data);
    return resp.data;
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const setMyIncidents = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: accidents, pagination },
    } = await axidentApi.get(
      `/users/me/accidents?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );

    const incidents = accidents.filter((a) => a.type === eventType.incident);

    commit("setIncidents", incidents);
    commit("setPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const setSiteIncidents = async (
  { commit },
  { id, selectedPage = 0, pageSize = 1000 }
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: incidents, pagination },
    } = await axidentApi.get(
      `/accidents?type=${eventType.incident}&status=${accidentStatus.sent}&status=${accidentStatus.notInjuried}&status=${accidentStatus.injuriedLicenseInsurance}&status=${accidentStatus.injuriedLicenseOthers}&status=${accidentStatus.returned}&status=${accidentStatus.closed}&selectedPage=${selectedPage}&pageSize=${pageSize}`
    );

    const siteIncidents = incidents.filter((a) => a.site.id === id);

    commit("setIncidents", siteIncidents);
    commit("setPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};
