export default {
  component: () =>
    import(
      /* webpackChunkName: "center-axidents" */ "@/modules/axident/layouts/CenterAxidentLayout.vue"
    ),
  children: [
    {
      path: ":personnelDivision?",
      name: "center-accidents",
      component: () =>
        import(
          /* webpackChunkName: "center-accidents" */ "@/modules/axident/views/CenterAccidents.vue"
        ),
    },
    {
      path: "details/:id",
      name: "center-accident-details",
      component: () =>
        import(
          /* webpackChunkName: "accident-details" */ "@/modules/axident/views/AccidentDetails.vue"
        ),
    },
  ],
};
