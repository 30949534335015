import axios from "axios";

const loginApi = axios.create({
  baseURL: process.env.VUE_APP_AXIDENT_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

loginApi.interceptors.request.use((config) => {
  config.headers = {
    Authorization: `Basic ${process.env.VUE_APP_BASIC_TOKEN}`,
  };
  return config;
});

export default loginApi;
