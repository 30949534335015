import { computed } from "vue";
import { useStore } from "vuex";
import { axidentRoles } from "@/enums";
import { useRoute, useRouter } from "vue-router";
import useAuth from "@/modules/auth/composables/useAuth";

export const siteRoles = {
  admin: [axidentRoles.role6],
  technical: [axidentRoles.role7],
  manager: [
    axidentRoles.role2,
    axidentRoles.role3,
    axidentRoles.role4,
    axidentRoles.role5,
  ],
  collaborator: [axidentRoles.role1],
  validatorRoles: [
    axidentRoles.role4,
    axidentRoles.role5,
    axidentRoles.role6,
    axidentRoles.role7,
  ],
};

const useRoles = () => {
  const store = useStore();
  const role = computed(() => store.getters["auth/getRole"]);

  const isCollaborator = computed(() =>
    siteRoles.collaborator.includes(role.value)
  );
  const isAdminOrTechnical = computed(() =>
    [...siteRoles.admin, ...siteRoles.technical].includes(role.value)
  );
  const isManager = computed(() => siteRoles.manager.includes(role.value));
  const isAdmin = computed(() => siteRoles.admin.includes(role.value));
  const isTechcnical = computed(() => siteRoles.technical.includes(role.value));
  const isValidatorRole = computed(() =>
    siteRoles.validatorRoles.includes(role.value)
  );

  const actionPlanRoles = () => {
    const roles = [
      axidentRoles.role3,
      axidentRoles.role4,
      axidentRoles.role5,
      axidentRoles.role6,
    ];
    return roles.includes(role.value);
  };

  const checkManagerRoles = () => {
    const roles = [
      axidentRoles.role2,
      axidentRoles.role3,
      axidentRoles.role4,
      axidentRoles.role5,
    ];
    return roles.includes(role.value);
  };

  const checkAdmRoles = () => {
    const roles = [axidentRoles.role6];
    return roles.includes(role.value);
  };

  const checkTechnicalRole = () => {
    const roles = [axidentRoles.role7];
    return roles.includes(role.value);
  };

  // Watch the effect of the role change if im changing my own role
  const useRouteGuard = () => {
    const router = useRouter();
    const route = useRoute();
    const { getRole } = useAuth();

    return () => {
      if (!route.meta || !route.meta.allowedRoles) {
        // If meta or allowedRoles its not defined, should do nothing
        return;
      }

      if (!route.meta.allowedRoles.includes(getRole.value)) {
        // If the user role its not allowed in this route, should kick him to home
        return router.push({ path: "/home" });
      }
    };
  };

  return {
    // Computed
    role,
    isCollaborator,
    isManager,
    isAdmin,
    isTechcnical,
    isValidatorRole,
    isAdminOrTechnical,

    // Methods
    actionPlanRoles,
    checkManagerRoles,
    checkAdmRoles,
    checkTechnicalRole,
    useRouteGuard,
  };
};

export default useRoles;
