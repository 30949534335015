export const getAccidentsSAP = (state) =>
  state.accidentsSAP
    .filter((accident) => accident.type === "ACCIDENT")
    .sort((a, b) => new Date(b.occurrenceDate) - new Date(a.occurrenceDate));

export const getPagination = (state) => state.pagination;

export const getErrorCode = (state) => state.errorMsg?.response?.data?.code;

export const getErrorMessage = (state) =>
  state.errorMsg?.response?.data?.description;

export const getLoadingState = (state) => state.isLoading;

export const getLoadingFetchingAccidents = (state) =>
  state.isLoadingFetchingAccidents;

export const getDownloadedSAPAccidents = (state) =>
  state.downloadedSAPAccidents;
