const axidentRoles = {
  role1: "ROL_COLABORATOR",
  role2: "ROL_MANAGER_SECTION",
  role3: "ROL_MANAGER_SECURITY",
  role4: "ROL_MANAGER_STORE",
  role5: "ROL_MANAGER_COACH",
  role6: "ROL_ADMIN_HR",
  role7: "ROL_ADMIN_PRL",
};

export default axidentRoles;
