const masterTypesId = {
  accidentStatus: "ACCIDENT_STATUS",
  accidentSeverityType: "ACCIDENT_SEVERITY_TYPE",
  contractType: "CONTRACT_TYPE",
  country: "COUNTRY",
  employeeSearchType: "EMPLOYEE_SEARCH_TYPE",
  hourRange: "HOUR_RANGE",
  identificationDocumentType: "IDENTIFICATION_DOCUMENT_TYPE",
  jobSeniority: "JOB_SENIORITY",
  accidentPlace: "ACCIDENT_PLACE",
  inItineraryType: "IN_ITINERARY_TYPE",
  usualWorkCenterType: "USUAL_WORK_CENTER_TYPE",
  usualWorkCenterZone: "USUAL_WORK_CENTER_ZONE",
  workedHours: "WORKED_HOURS",
  activityWasDoing: "ACTIVITY_WAS_DOING",
  accidentCause: "ACCIDENT_CAUSE",
  accidentWorkType: "WORKTYPE",
  basicCause: "BASIC_CAUSE",
  rrhh: "RRHH_REGION",
  autonomousCommunity: "AUTONOMOUS_COMMUNITY",
  siteAdditionalInfo: "SITE_ADDITIONAL_INFO_TYPE",
  medicalReportType: "MEDICAL_REPORT_TYPE",
  medicalReportCauseType: "MEDICAL_REPORT_CAUSE_TYPE",
  medicalReportForecastType: "MEDICAL_REPORT_FORECAST_TYPE",
  medicalReportContactWayType: "MEDICAL_REPORT_CONTACT_WAY_TYPE",
  medicalReportInjuriedPartType: "MEDICAL_REPORT_INJURIED_PART_TYPE",
  medicalReportStandardInjuryDescriptionType:
    "MEDICAL_REPORT_STANDARD_INJURY_DESCRIPTION_TYPE",
};

export default masterTypesId;
