// export const myGetter = (state) => { return state.something }

export const getCountriesFilters = (state) => state.countries;

export const getContractsFilters = (state) => state.contracts;

export const getSenioritiesFilters = (state) => state.seniorities;

export const getDocumentsFilters = (state) => state.documents;

export const getSearchTypesFilters = (state) => state.searchTypes;

export const getAccidentPlaces = (state) => state.places;

export const getUsualWorkCenterType = (state) => state.usualWorkCenterType;

export const getUsualWorkCenterZone = (state) => state.usualWorkCenterZone;

export const getSeverities = (state) => state.severities;

export const getInItinereOpts = (state) => state.inItinereOpts;

export const getWorkedHours = (state) => state.workedHours;

export const getActivities = (state) => state.activities;

export const getCauses = (state) => state.causes;

export const getBasicCauses = (state) => state.basicCauses;

// export const getWorkTypes = (state) => state.workTypes;
export const getWorkTypes = () => [
  {
    description: "Picking reapro",
    enabled: true,
    id: "11",
    label: "Picking reapro",
    parent: null,
  },
  {
    description: "Descarga o carga",
    enabled: true,
    id: "12",
    label: "Descarga o carga",
    parent: null,
  },
  {
    description: "Inventario / gestión stock",
    enabled: true,
    id: "19",
    label: "Inventario / gestión stock",
    parent: null,
  },
  {
    description: "Tienda",
    enabled: true,
    id: "43",
    label: "Tienda",
    parent: null,
  },
  {
    description: "Actividades deportivas",
    enabled: true,
    id: "69",
    label: "Actividades deportivas",
    parent: null,
  },
  {
    description: "Otros, in itienere, en otra empresa",
    enabled: true,
    id: "99",
    label: "Otros, in itienere, en otra empresa",
    parent: null,
  },
];

export const getAutonomousCommunities = (state) => state.autonomousCommunities;

export const getRegions = (state) => state.regions;

export const getMedicalReportTypes = (state) => state.medicalReportTypes;

export const getMedicalReportCauseTypes = (state) =>
  state.medicalReportCauseTypes;

export const getMedicalReportForecastTypes = (state) =>
  state.medicalReportForecastTypes;

export const getMedicalReportContactWayTypes = (state) =>
  state.medicalReportContactWayTypes;

export const getMedicalReportInjuriedPartTypes = (state) =>
  state.medicalReportInjuriedPartTypes;

export const getMedicalReportStandardInjuryDescriptionTypes = (state) =>
  state.medicalReportStandardInjuryDescriptionTypes;

export const getSiteAdditionalInfo = (state) => state.siteAdditionalInfo;

export const getPagination = (state) => state.pagination;

export const getErrorMessage = (state) => state.errorMsg?.response?.data?.code;

export const getErrorDescription = (state) =>
  state.errorMsg?.response?.data?.description;

export const getLoadingState = (state) => state.isLoading;

export const getFilterOpts = (state) => (filter) => {
  if (filter === "documents")
    return state.documents.map((d) => ({
      label: d.label,
      id: d.id,
    }));
};
