// export const myGetter = (state) => { return state.something }

export const getUsers = (state) => state.users;

export const getUser = (state) => state.user;

export const getPagination = (state) => state.pagination;

export const getErrorMessage = (state) => state.errorMsg?.response?.data?.code;

export const getLoadingState = (state) => state.isLoading;

export const filteredUsers = (state) => (searchTerm, keys) => {
  return state.users.filter((user) =>
    keys.some((key) =>
      String(user[key]).toLowerCase().includes(searchTerm.trim().toLowerCase())
    )
  );
};
