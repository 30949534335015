// export const myGetter = (state) => { return state.something }

export const getCenters = (state) => state.centers;

export const getCenter = (state) => state.center;

export const getCenterById = (state) => (id) =>
  state.centers.find((s) => s.id === id);

export const getPagination = (state) => state.pagination;

export const getErrorCode = (state) => state.errorMsg?.response?.data?.code;

export const getError = (state) => state.errorMsg?.response?.data?.description;

export const getErrorDetails = (state) =>
  state.errorMsg?.response?.data?.details;

export const getLoadingState = (state) => state.isLoading;
