// export const myAction = async ({commit}) => {}

import axidentApi from "../../api/axidentApi";
import { handleTokenExpired } from "../../helpers/handleTokenExpired";

export const getJobs = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: jobs, pagination },
    } = await axidentApi.get(
      `/jobs?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setJobs", jobs);
    commit("setPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const updateRole = async ({ commit, state }, payload) => {
  const { jobId, newRoleId } = payload;
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const { data } = await axidentApi.patch(`/jobs/${jobId}`, {
      role: {
        id: newRoleId,
      },
    });

    // Create new array with the updated values
    const updatedJobs = state.jobs.map((job) => {
      if (job.id === data.id) {
        return data;
      }
      return job;
    });
    // Commit the updated state to vuex to update the view
    commit("setJobs", updatedJobs);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};
