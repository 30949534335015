// export const myAction = async ({commit}) => {}
import { authApi, loginApi } from "../api";
import { axidentErrors } from "@/enums";
import useAuth from "../composables/useAuth";
import { userToCreate, userToLogout } from "../helpers/authHelpers";

// Constants
const constants = {
  accessTokenKey: "accessToken",
  idTokenKey: "idToken",
  userNotFound: axidentErrors.codeUser01,
  userDisabled: axidentErrors.codeUser02,
  generalError: axidentErrors.codeGeneral01,
  jobNotFound: axidentErrors.codeJob01,
  anotherUserWasFound: axidentErrors.codeUser04,
  tokenExpired: axidentErrors.codeToken03,
};

// Get the access token and identity token from localStorage
const getTokensFromLocalStorage = () => {
  const accessToken = localStorage.getItem(constants.accessTokenKey);
  const idToken = localStorage.getItem(constants.idTokenKey);
  return { accessToken, idToken };
};

// Auth error handler
const handleAuthError = (commit, error) => {
  commit("setLoginError");
  commit("setErrorMessage", error);
  throw new Error(error.response.data.description);
};

// Constant auth error handler
const errorHandlers = {
  [axidentErrors.codeLogin01]: handleAuthError,
  [axidentErrors.codeAuth01]: handleAuthError,
  default: (commit) => {
    commit("logout");
  },
};

// Check if the user is logged in
// Auth function
export const checkAuthentication = async ({ commit, dispatch }, payload) => {
  const { setFedIdUser } = useAuth();
  const { accessToken, idToken } = getTokensFromLocalStorage();

  if (accessToken) {
    try {
      commit("setLoadingState", true);
      // If token exists in localstorage, the current user is used
      dispatch("setCurrentUser");
      setFedIdUser(idToken);
      commit("loginUser", { access_token: accessToken, id_token: idToken });
      return { access_token: accessToken, id_token: idToken };
    } catch (error) {
      handleError(error, dispatch, commit);
      commit("setErrorMessage", error);
    } finally {
      commit("setLoadingState", false);
    }
  }

  try {
    // If the user doesn't exists, the login method its called
    const { data } = await loginApi.get(`/login`, {
      params: { code: payload },
    });
    dispatch("setCurrentUser");
    commit("loginUser", data);
    setFedIdUser(data.id_token);
    return data;
  } catch (error) {
    const errorHandler =
      errorHandlers[error.response.data.code] || errorHandlers.default;
    errorHandler(commit, error);
  }
};

// Get the additional information from epplaning
export const onSearchUser = async ({ commit }, payload) => {
  try {
    let url = "/employees?";

    // For testing call
    // const { data } = await authApi.get(
    //   `${url}email=marabel.collinge@decathlon.com&pageSize=250`
    // );

    const { data } = await authApi.get(
      `${url}email=${payload.email}&pageSize=1000`
    );
    commit("updateCurrentUser", data.data[0]);
  } catch (error) {
    commit("setErrorMessage", error);
  } finally {
    commit("setLoadingState", false);
  }
};

// Get the information of the current user
export const setCurrentUser = async ({ commit, dispatch }) => {
  try {
    commit("setLoadingState", true);
    const { data } = await authApi.get("/users/me");
    dispatch("onSearchUser", data);
    commit("setCurrentUser", data);
    return data;
  } catch (error) {
    handleError(error, dispatch, commit);
    commit("setErrorMessage", error);
  } finally {
    commit("setLoadingState", false);
  }
};

// Create the user according the fedId information
export const createUser = async ({ state, commit, dispatch }) => {
  try {
    commit("setLoadingState", true);
    const user = userToCreate(state.fedIdUser);
    await authApi.post("/users/me", user);
    dispatch("setCurrentUser");
    return user;
  } catch (error) {
    handleError(error, dispatch, commit);
    commit("setErrorMessage", error);
  } finally {
    commit("setLoadingState", false);
  }
};

const handleError = (error, dispatch, commit) => {
  if (error.response?.data.code === constants.userNotFound) {
    return dispatch("createUser");
  } else if (
    error.response?.data.code === constants.userDisabled ||
    error.response?.data.code === constants.generalError ||
    error.response?.data.code === constants.anotherUserWasFound ||
    error.response?.data.code === constants.jobNotFound
  ) {
    commit("setUserAsDisabled");
  } else if (error.response?.data.code === constants.tokenExpired) {
    commit("sessionExpired");
  } else {
    throw new Error(error.response.data.description);
  }
};

export const logout = async ({ state, commit }) => {
  try {
    commit("setLoadingState", true);
    const pi_sri = userToLogout(state.fedIdUser);
    await authApi.post("/revokeSession", pi_sri);
    commit("logout");
    return pi_sri;
  } catch (error) {
    commit("setErrorMessage", error);
  } finally {
    commit("setLoadingState", false);
  }
};
