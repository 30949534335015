import i18n from "@/plugins/i18n";
import { isHrGuard } from "./roles-guards";

const { t } = i18n.global;

export default {
  component: () =>
    import(
      /* webpackChunkName: "insurance-centers" */ "@/modules/axident/layouts/AxidentAdminInsuranceCenterLayout.vue"
    ),
  children: [
    {
      path: "",
      name: "insurance-center",
      meta: {
        allowedRoles: ["ROL_ADMIN_HR"],
        breadCrumb: t("breadCrumbs.insuranceCenter"),
      },
      beforeEnter: [isHrGuard],
      component: () =>
        import(
          /* webpackChunkName: "inscurance-centers-list" */ "@/modules/axident/views/InsuranceCenters.vue"
        ),
    },
    {
      path: ":id",
      name: "insurance-center-details",
      meta: {
        allowedRoles: ["ROL_ADMIN_HR"],
      },
      beforeEnter: [isHrGuard],
      component: () =>
        import(
          /* webpackChunkName: "insurance-center-details" */ "@/modules/axident/views/InsuranceCenterDetails.vue"
        ),
    },
  ],
};
