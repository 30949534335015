// export const myMutation = (state) => { do something }

export const setCenters = (state, centers) => {
  state.centers = centers;
};

export const setCenter = (state, center) => {
  state.center = center;
};

export const updateCenter = (state, updatedCenter) => {
  // Update the list of centers
  state.centers = state.centers.map((center) => {
    if (center.id === updatedCenter.id) {
      return updatedCenter;
    }
    return center;
  });
};

export const addNewCenter = (state, center) => {
  state.centers = [center, ...state.centers];
};

export const setPagination = (state, pagination) => {
  state.pagination = pagination;
};

export const setErrorMessage = (state, message) => {
  state.errorMsg = message;
};

export const setLoadingState = (state, value) => {
  state.isLoading = value;
};
