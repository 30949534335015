import i18n from "@/plugins/i18n";
import { isHrGuard } from "./roles-guards";

const { t } = i18n.global;

export default {
  component: () =>
    import(
      /* webpackChunkName: "center-and-zones" */ "@/modules/axident/layouts/AxidentAdminCentersLayout.vue"
    ),
  children: [
    {
      path: "",
      name: "centers",
      meta: {
        allowedRoles: ["ROL_ADMIN_HR"],
        breadCrumb: t("breadCrumbs.centerAndZones"),
      },
      beforeEnter: [isHrGuard],
      component: () =>
        import(
          /* webpackChunkName: "center" */ "@/modules/axident/views/AxidentAdminCenters.vue"
        ),
    },
    {
      path: ":id",
      name: "center-details",
      meta: {
        allowedRoles: ["ROL_ADMIN_HR"],
      },
      beforeEnter: [isHrGuard],
      component: () =>
        import(
          /* webpackChunkName: "center-details" */ "@/modules/axident/views/AxidentAdminCenterDetails.vue"
        ),
    },
  ],
};
