const axidentErrors = {
  // General
  codeGeneral01: "AXIDENT_GENERAL", // Something went wrong! Please contact with the administrator.
  codeGeneral02: "AXIDENT_APP_INSUFFICIENT_GRANT", // "Insufficient grant access to the resource."

  // Login
  codeLogin01: "AXIDENT_LOGIN_001", // An error was found when tries to login: {"error_description":"Authorization code is malformed.","error":"invalid_grant"}

  // User
  codeUser01: "AXIDENT_USER_001", // The user with the following id * does not exists.
  codeUser02: "AXIDENT_USER_002", // Your user with the following identifier has been disabled. Please, contact with some AXIDENT Administrator!
  codeUser04: "AXIDENT_USER_004", // It was found another user with the following information "id [f1d24819-efd8-3113-8509-78fa85c13b04]"
  codeUser09: "AXIDENT_USER_009", // "The modifier user have role (ROL_MANAGER_STORE) and this role is lower than at least one of the input role(s). You only can assign a lower role to user f1d24819-efd8-3113-8509-78fa85c13b04"

  // Token
  codeToken03: "AXIDENT_TOKEN_003", // "The input token has expired."

  // Auth
  codeAuth01: "AXIDENT_APP_AUTH_HEADER_002", // "The provided basic authorization header is wrong."

  // Jobs
  codeJob01: "AXIDENT_JOB_001", // The job with the following id * does not exists.
  codeJob02: "AXIDENT_JOB_002", // It was already found another job with the following identifier [5000072]

  // Accidents
  codeAccident02: "AXIDENT_ACCIDENT_002", // "There is another accident opened in DRAFT status with following identifier [790f5b39-ac4d-49cd-aab1-7167af890561]."
  codeAccident06: "AXIDENT_ACCIDENT_006", // It is not possible create an accident to the future. An accident should be occured now or in the past.
  codeAccident07: "AXIDENT_ACCIDENT_007", // The selected usual work center zone [01] is not valid for the selected usual work center type [02].
  codeAccident08: "AXIDENT_ACCIDENT_008", // The accident have conflicting information for the selected accident place type [01]. You must remove that conflicting information. Please, check the details!
  codeAccident09: "AXIDENT_ACCIDENT_009", // The selected usual work center zone [01] is not valid for the selected usual work center type [02].
  codeAccident13: "AXIDENT_ACCIDENT_013", // No accident type selected! For the following accident type [UNKNOWN], some information are conflictive. Please, check the details!
  codeAccident23: "AXIDENT_ACCIDENT_023", // It was not possible to communicate the accident to the insurance company!

  // Input
  codeInput01: "INPUT_DATA_VALIDATION", // Parse JSON error

  // Master
  codeMaster01: "AXIDENT_MASTER_VALUE_001", // "The master table value with the following id [Parcial] does not exists for the master type CONTRACT_TYPE."

  // Grant access
  codeGrantAccess01: "AXIDENT_APP_INSUFFICIENT_GRANT", // Insufficient grant access to the resource.

  // Insurance center
  codeInsuranceCenter02: "AXIDENT_INSURANCE_CENTER_002", // There is another insurance center with the following identifier *.
  codeInsuranceCenter04: "AXIDENT_INSURANCE_CENTER_004", // Some sites associated with the insurance center are missing. Please check the details!
};

export default axidentErrors;
