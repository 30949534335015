import isAdminGuard, { isAllowed } from "./roles-guards";
import i18n from "@/plugins/i18n";
import adminRouter from "./admin-router";
import myAxidentsRouter from "./my-axidents-router";
import myIncidentsRouter from "./my-incidents-router";
import centerAxidentRouter from "./center-axident-router";
import centerIncidentRouter from "./center-incident-router";
import countryAxidentRouter from "./country-axident-router";
import countryIncidentRouter from "./country-incident-router";

const { t } = i18n.global;

export default {
  name: "axident",
  component: () =>
    import(
      /* webpackChunkName: "axident" */ "@/modules/axident/layouts/AxidentLayout.vue"
    ),
  children: [
    {
      path: "/axident-home",
      alias: ["", "home"],
      name: "axident-home",
      component: () =>
        import(
          /* webpackChunkName: "home" */ "@/modules/axident/views/AxidentHome.vue"
        ),
    },
    {
      path: "/axident-admin",
      meta: {
        allowedRoles: ["ROL_ADMIN_HR", "ROL_ADMIN_PRL"],
        breadCrumb: t("breadCrumbs.admin"),
      },
      ...adminRouter,
    },
    {
      path: "/create-axident",
      name: "create-axident",
      meta: {
        allowedRoles: [
          "ROL_MANAGER_SECTION",
          "ROL_MANAGER_SECURITY",
          "ROL_MANAGER_STORE",
          "ROL_MANAGER_COACH",
          "ROL_ADMIN_HR",
          "ROL_ADMIN_PRL",
        ],
        breadCrumb: t("breadCrumbs.accident"),
      },
      beforeEnter: [isAllowed],
      component: () =>
        import(
          /* webpackChunkName: "create-axident" */ "@/modules/axident/views/AxidentForm.vue"
        ),
    },
    {
      path: "/create-incident",
      name: "create-incident",
      meta: {
        allowedRoles: [
          "ROL_MANAGER_SECTION",
          "ROL_MANAGER_SECURITY",
          "ROL_MANAGER_STORE",
          "ROL_MANAGER_COACH",
          "ROL_ADMIN_HR",
          "ROL_ADMIN_PRL",
        ],
        breadCrumb: t("breadCrumbs.incident"),
      },
      beforeEnter: [isAllowed],
      component: () =>
        import(
          /* webpackChunkName: "create-incident" */ "@/modules/axident/views/IncidentForm.vue"
        ),
    },
    {
      path: "/site",
      name: "axident-site",
      meta: {
        allowedRoles: ["ROL_ADMIN_HR", "ROL_ADMIN_PRL"],
        breadCrumb: t("breadCrumbs.site"),
      },
      beforeEnter: [isAdminGuard],
      component: () =>
        import(
          /* webpackChunkName: "site" */ "@/modules/axident/views/AxidentSite.vue"
        ),
    },
    {
      path: "/my-accidents",
      meta: {
        breadCrumb: t("breadCrumbs.myAccidents"),
      },
      ...myAxidentsRouter,
    },
    {
      path: "/my-incidents",
      meta: {
        breadCrumb: t("breadCrumbs.myIncidents"),
      },
      ...myIncidentsRouter,
    },
    {
      path: "/center-accidents",
      meta: {
        allowedRoles: [
          "ROL_MANAGER_SECTION",
          "ROL_MANAGER_SECURITY",
          "ROL_MANAGER_STORE",
          "ROL_MANAGER_COACH",
          "ROL_ADMIN_HR",
          "ROL_ADMIN_PRL",
        ],
        breadCrumb: t("breadCrumbs.centerAccidents"),
      },
      beforeEnter: [isAllowed],
      ...centerAxidentRouter,
    },
    {
      path: "/center-incidents",
      meta: {
        allowedRoles: [
          "ROL_MANAGER_SECTION",
          "ROL_MANAGER_SECURITY",
          "ROL_MANAGER_STORE",
          "ROL_MANAGER_COACH",
          "ROL_ADMIN_HR",
          "ROL_ADMIN_PRL",
        ],
        breadCrumb: t("breadCrumbs.centerIncidents"),
      },
      beforeEnter: [isAllowed],
      ...centerIncidentRouter,
    },
    {
      path: "/country-accidents",
      meta: {
        allowedRoles: ["ROL_ADMIN_HR", "ROL_ADMIN_PRL"],
        breadCrumb: t("breadCrumbs.countryAccidents"),
      },
      beforeEnter: [isAdminGuard],
      ...countryAxidentRouter,
    },
    {
      path: "/country-incidents",
      meta: {
        allowedRoles: ["ROL_ADMIN_HR", "ROL_ADMIN_PRL"],
        breadCrumb: t("breadCrumbs.countryIncidents"),
      },
      beforeEnter: [isAdminGuard],
      ...countryIncidentRouter,
    },
    {
      path: "/my-profile",
      name: "my-profile",
      meta: {
        breadCrumb: t("breadCrumbs.myProfile"),
      },
      component: () =>
        import(
          /* webpackChunkName: "my-profile" */ "@/modules/axident/views/MyProfile.vue"
        ),
    },
  ],
};
