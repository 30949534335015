import store from "@/store";
import { axidentErrors } from "@/enums";

const handleTokenExpired = (error) => {
  if (error && error.response?.data?.code === axidentErrors.codeToken03) {
    return store.commit("auth/sessionExpired");
  }
};

export { handleTokenExpired };
