// export const myMutation = (state) => { do something }

export const setAccidents = (state, accidents) => {
  state.accidents = accidents;
};

export const setAccident = (state, accident) => {
  state.accident = accident;
};

export const addAccident = (state, accident) => {
  state.accidents = [accident, ...state.accidents];
};

export const setAffectedUser = (state, affectedUser) => {
  state.affectedUser = affectedUser;
};

export const setPagination = (state, pagination) => {
  state.pagination = pagination;
};

export const setErrorMessage = (state, message) => {
  state.errorMsg = message;
};

export const setLoadingState = (state, value) => {
  state.isLoading = value;
};
