export default {
  component: () =>
    import(
      /* webpackChunkName: "country-axidents" */ "@/modules/axident/layouts/CountryAxidentLayout.vue"
    ),
  children: [
    {
      path: "",
      name: "country-accidents",
      component: () =>
        import(
          /* webpackChunkName: "country-accidents" */ "@/modules/axident/views/AccidentManagement.vue"
        ),
    },
    {
      path: ":id",
      name: "country-accident-details",
      component: () =>
        import(
          /* webpackChunkName: "accident-details" */ "@/modules/axident/views/AccidentDetails.vue"
        ),
    },
  ],
};
