export default () => ({
  // Corresponding with MASTERDATA
  stores: [],
  store: {},
  storesPagination: {},
  // Corresponding with AXIDENTPLUS DB
  sites: [],
  site: {},
  checkedSites: [],
  sitesPagination: {},
  errorMsg: "",
  isLoading: false,
});
