// export const myGetter = (state) => { return state.something }

// Corresponding with AXIDENTPLUS
export const getSites = (state) => state.sites;

export const getSitesByRegion = (state) => (selectedRegion) =>
  state.sites.filter(
    (region) =>
      region.customAdditionalInfo?.autonomousCommunity === selectedRegion
  );

export const getSitesChecked = (state) => state.checkedSites;

export const getStores = (state) => state.stores;

export const getSite = (state) => state.site;

export const getStore = (state) => state.store;

// Corresponding with MASTERDATA

// Filter the stores by code, name and municipality
export const filteredSites = (state) => (searchTerm) => {
  if (searchTerm) {
    return state.sites.filter(
      ({
        code,
        name,
        address: {
          municipality: { name: cityName },
        },
      }) => {
        return searchTerm
          .toLowerCase()
          .split(" ")
          .every((term) =>
            [code, name, cityName].some((value) =>
              value.toLowerCase().includes(term)
            )
          );
      }
    );
  } else {
    return state.sites;
  }
};

export const getSiteById = (state) => (id) =>
  state.sites.find((s) => s.id === id);

export const getStoreById = (state) => (id) =>
  state.stores.find((s) => s.id === id);

export const getSitesPagination = (state) => state.sitesPagination;

export const getStoresPagination = (state) => state.storesPagination;

export const getErrorMessage = (state) => state.errorMsg?.response?.data?.code;

export const getError = (state) => state.errorMsg?.response?.data?.description;

export const getErrorDetails = (state) =>
  state.errorMsg?.response?.data?.details;

export const getLoadingState = (state) => state.isLoading;
