// export const myMutation = (state) => { do something }

export const setIncidents = (state, incidents) => {
  state.incidents = incidents;
};

export const setIncident = (state, incident) => {
  state.incident = incident;
};

export const setPagination = (state, pagination) => {
  state.pagination = pagination;
};

export const setErrorMessage = (state, message) => {
  state.errorMsg = message;
};

export const setLoadingState = (state, value) => {
  state.isLoading = value;
};
