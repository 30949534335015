<template v-cloak>
  <div v-if="getLoginError">
    <ContactAdministrator />
  </div>
  <div v-if="getSessionStatus">
    <SessionExpired />
  </div>
  <div v-else-if="getFedIdUser && !getUserStatus">
    <ContactAdministrator />
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import { defineAsyncComponent, onMounted, watch } from "vue";
import { useStore } from "vuex";
import useAuth from "@/modules/auth/composables/useAuth";

export default {
  components: {
    ContactAdministrator: defineAsyncComponent(() =>
      import("@/modules/shared/views/ContactAdministrator.vue")
    ),
    SessionExpired: defineAsyncComponent(() =>
      import("@/modules/shared/views/SessionExpired.vue")
    ),
  },
  setup() {
    const {
      getCurrentUser,
      getFedIdUser,
      getUserStatus,
      getLoginError,
      getSessionStatus,
      authToken,
      getRole,
    } = useAuth();

    const store = useStore();

    // Sets the filters for documents, contracts, seniorities, and countries
    // by dispatching actions to the Vuex store. It uses async/await to ensure that each
    // dispatch has completed before moving on to the next one. If there is an error, it is
    // caught, saved in store and logged to the console.
    const setFilters = async () => {
      try {
        await Promise.all([
          store.dispatch("filters/setDocumentsFilter"),
          store.dispatch("filters/setContractsFilter"),
          store.dispatch("filters/setSenioritiesFilter"),
          store.dispatch("filters/setCountriesFilter"),
          store.dispatch("filters/setSearchTypesFilters"),
          store.dispatch("filters/setUsualWorkCenterType"),
          store.dispatch("filters/setUsualWorkCenterZone"),
          store.dispatch("filters/setSeverities"),
          store.dispatch("filters/setInItinereTypes"),
          store.dispatch("filters/setAccidentPlaces"),
          store.dispatch("filters/setWorkedHours"),
          store.dispatch("filters/setActivities"),
          store.dispatch("filters/setCauses"),
          store.dispatch("filters/setWorkTypes"),
          store.dispatch("filters/setBasicCauses"),
          store.dispatch("filters/setRrhhRegions"),
          store.dispatch("filters/setAutonomousCommunities"),
        ]);
      } catch (error) {
        console.error(error);
        store.commit("filters/setErrorMessage", error);
      }
    };

    // Creates a promise that resolves when the authToken has a value.
    // It uses the watch function from Vue to monitor changes to authToken, and resolves
    // the promise when a non-null value is detected.
    const waitForAuthToken = () =>
      new Promise((resolve) => {
        const unwatch = watch(authToken, (newVal) => {
          if (newVal !== null) {
            unwatch(); // Stops watching authToken after the promise is resolved
            resolve();
          }
        });
      });

    // When the component is mounted waits for authToken to have
    // a value using the waitForAuthToken function, and then calls setFilters.
    onMounted(async () => {
      await waitForAuthToken(); // Wait for authToken to have a value
      await setFilters(); // Call setFilters when authToken has a value
      localStorage.setItem("role", getRole.value);
    });

    watch(
      () => getRole.value,
      (newVal) => {
        localStorage.setItem("role", newVal);
      }
    );

    return {
      getCurrentUser,
      getFedIdUser,
      getLoginError,
      getUserStatus,
      getSessionStatus,
    };
  },
};
</script>

<style lang="scss">
body {
  background-color: var(--vtmn-semantic-color_background-tertiary);
}
</style>
