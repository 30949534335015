// export const myGetter = (state) => { return state.something }

import { axidentRoles } from "@/enums";

export const authToken = (state) => state.accessToken;

export const getUserId = (state) => state.currentUser.id;

export const getFedIdUser = (state) => state.fedIdUser;

export const getCurrentUser = (state) => state.currentUser;

export const getUserStatus = (state) => state.enabled;

export const sessionStatus = (state) => state.sessionExpired;

export const getLoginError = (state) => state.loginError;

export const getErrorCode = (state) => state.errorMsg?.response?.data?.code;

export const getErrorDescription = (state) =>
  state.errorMsg?.response?.data?.description;

export const getErrorMessage = (state) => state.errorMsg?.response?.data;

export const getLoadingState = (state) => state.isLoading;

export const getRole = (state) => {
  if (!state.currentUser) return state;
  if (state.currentUser?.role?.id) return state.currentUser.role.id;
  if (state.currentUser?.job.role?.id) return state.currentUser.job.role.id;
  if (
    state.currentUser?.job?.role === null ||
    state.currentUser?.job?.role === undefined
  )
    return axidentRoles.role1;
};
