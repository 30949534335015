export default {
  currentUser: null,
  accessToken: null,
  idToken: null,
  fedIdUser: null,
  enabled: null,
  sessionExpired: null,
  loginError: null,
  errorMsg: "",
  isLoading: false,
};
