export default {
  component: () =>
    import(
      /* webpackChunkName: "center-incidents" */ "@/modules/axident/layouts/CenterIncidentLayout.vue"
    ),
  children: [
    {
      path: "",
      name: "center-incidents",
      component: () =>
        import(
          /* webpackChunkName: "center-incidents" */ "@/modules/axident/views/CenterIncidents.vue"
        ),
    },
    {
      path: ":id",
      name: "center-incident-details",
      component: () =>
        import(
          /* webpackChunkName: "incident-details" */ "@/modules/axident/views/IncidentDetails.vue"
        ),
    },
  ],
};
