import { createStore } from "vuex";
import axidentModule from "@/modules/axident/store";
import authModule from "@/modules/auth/store";
import sitesModule from "@/modules/axident/store/sites";
import usersModule from "@/modules/axident/store/users";
import accidentsModule from "@/modules/axident/store/accidents";
import accidentsSAPModule from "@/modules/axident/store/accidentsSAP";
import incidentsModule from "@/modules/axident/store/incidents";
import jobsModule from "@/modules/axident/store/jobs";
import filtersModule from "@/modules/axident/store/filters";
import insuranceCentersModule from "@/modules/axident/store/insurance-centers";

const store = createStore({
  modules: {
    auth: authModule,
    axident: axidentModule,
    sites: sitesModule,
    users: usersModule,
    accidents: accidentsModule,
    accidentsSAP: accidentsSAPModule,
    incidents: incidentsModule,
    jobs: jobsModule,
    filters: filtersModule,
    insuranceCenters: insuranceCentersModule,
  },
});

export default store;
