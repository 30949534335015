import i18n from "@/plugins/i18n";
import isAdminGuard from "./roles-guards";

const { t } = i18n.global;

export default {
  component: () =>
    import(
      /* webpackChunkName: "profiles" */ "@/modules/axident/layouts/AxidentAdminProfilesLayout.vue"
    ),
  children: [
    {
      path: "",
      name: "profiles",
      meta: {
        allowedRoles: ["ROL_ADMIN_HR", "ROL_ADMIN_PRL"],
        breadCrumb: t("breadCrumbs.profiles"),
      },
      beforeEnter: [isAdminGuard],
      component: () =>
        import(
          /* webpackChunkName: "profiles-list" */ "@/modules/axident/views/AxidentAdminProfiles.vue"
        ),
    },
    {
      path: ":id",
      name: "profile-details",
      meta: {
        allowedRoles: ["ROL_ADMIN_HR", "ROL_ADMIN_PRL"],
      },
      beforeEnter: [isAdminGuard],
      component: () =>
        import(
          /* webpackChunkName: "profile-details" */ "@/modules/axident/views/AxidentAdminProfileDetails.vue"
        ),
    },
  ],
};
