// export const myMutation = (state) => { do something }

export const setJobs = (state, jobs) => {
  state.jobs = jobs;
};

export const setJob = (state, job) => {
  state.job = job;
};

export const setPagination = (state, pagination) => {
  state.pagination = pagination;
};

export const setErrorMessage = (state, message) => {
  state.errorMsg = message;
};

export const setLoadingState = (state, value) => {
  state.isLoading = value;
};
