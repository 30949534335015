import { createRouter, createWebHistory } from "vue-router";
import NProgress from "nprogress";
import axidentRouter from "@/modules/axident/router";
import useAuth from "@/modules/auth/composables/useAuth";
import { checkTokenDate } from "@/modules/auth/helpers/authHelpers";
// import store from "@/store";

// Hide Nprogress spinner
NProgress.configure({ showSpinner: false });

const routes = [
  {
    path: "/",
    meta: { authRequired: true },
    ...axidentRouter,
  },
  {
    path: "/logout",
    name: "logout",
    meta: { authRequired: false },
    component: () =>
      import(
        /* webpackChunkName: "logout" */ "@/modules/shared/views/LogoutView.vue"
      ),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    meta: { authRequired: false },
    component: () =>
      import(
        /* webpackChunkName: "404-view" */ "@/modules/shared/views/PageNotFound.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start();
  }
  const { setExpiredSession, checkAuthStatus } = useAuth();

  const authRequired = to.matched.some((route) => route.meta.authRequired);

  // If auth isn't required for the route, just continue.
  if (!authRequired) return next();

  const resp = await checkAuthStatus(to.query.code);
  const isLoggedIn = resp;

  if (isLoggedIn && checkTokenDate(isLoggedIn.access_token)) {
    setExpiredSession();
  }

  if (authRequired && isLoggedIn.access_token) return next();

  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
