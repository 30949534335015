// export const myMutation = (state) => { do something }

export const setUsers = (state, users) => {
  state.users = users;
};

export const setEmployees = (state, employees) => {
  state.employees = employees;
};

export const setUser = (state, user) => {
  state.user = user;
};

export const addUser = (state, user) => {
  state.users = [user, ...state.users];
};

export const updateUserRole = (state, newRole) => {
  if (state.user) {
    const updatedUser = Object.assign({}, state.user, {
      role: { id: newRole },
    });
    state.user = updatedUser;
  }
};

export const setPagination = (state, pagination) => {
  state.pagination = pagination;
};

export const setErrorMessage = (state, message) => {
  state.errorMsg = message;
};

export const setLoadingState = (state, value) => {
  state.isLoading = value;
};
