const accidentStatus = {
  draft: "DRAFT",
  sent: "SENT",
  notInjuried: "NOT_INJURIED",
  injuriedLicenseInsurance: "INJURIED_LICENSE_TO_BE_OUT_INSURANCE",
  injuriedLicenseOthers: "INJURIED_LICENSE_TO_BE_OUT_OTHER",
  returned: "RETURNED",
  closed: "CLOSED",
  relapse: "RELAPSE",
};

export default accidentStatus;
