import axidentApi from "../../api/axidentApi";
import { handleTokenExpired } from "../../helpers/handleTokenExpired";

export const fetchAccidentsSAPAction = async ({ commit, state }, payload) => {
  let selectedPage = 0;
  let pageSize = 1000;
  let editedPayload =
    typeof payload === "number" ? state.searchFormFilter : payload;

  if (typeof payload === "number") {
    selectedPage = payload;
  }

  const filtersArr = [];

  Object.keys(editedPayload).forEach((key) => {
    if (editedPayload[key]) {
      if (typeof editedPayload[key] === "object") {
        editedPayload[key].forEach((value) => {
          filtersArr.push(`${key}=${value}`);
        });
      } else {
        filtersArr.push(`${key}=${editedPayload[key]}`);
      }
    }
  });
  const queryString = filtersArr.length > 0 ? "?" + filtersArr.join("&") : "";

  try {
    commit("setLoadingFetchingAccidentsState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: accidents, pagination },
    } = await axidentApi.get(
      `hr/accidents${queryString}&selectedPage=${selectedPage}&pageSize=${pageSize}`
    );

    const siteAccidentsSAP = accidents;

    commit("setAccidentsSAP", siteAccidentsSAP);
    commit("setFiltersMutationSAP", editedPayload);
    commit("setPagination", pagination);
  } catch (error) {
    console.error(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingFetchingAccidentsState", false);
  }
};

export const fetchCenterAccidentsAction = async (
  { commit, state },
  payload
) => {
  let selectedPage = 0;
  let pageSize = 1000;
  let editedPayload =
    typeof payload === "number" ? state.searchFormFilter : payload;

  if (typeof payload === "number") {
    selectedPage = payload;
  }

  const filtersArr = [];

  Object.keys(editedPayload).forEach((key) => {
    if (editedPayload[key]) {
      if (typeof editedPayload[key] === "object") {
        editedPayload[key].forEach((value) => {
          filtersArr.push(`${key}=${value}`);
        });
      } else {
        filtersArr.push(`${key}=${editedPayload[key]}`);
      }
    }
  });
  const queryString = filtersArr.length > 0 ? "?" + filtersArr.join("&") : "";

  try {
    commit("setLoadingFetchingAccidentsState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: accidents, pagination },
    } = await axidentApi.get(
      `/accidents/filtered${queryString}&selectedPage=${selectedPage}&pageSize=${pageSize}`
    );

    const siteAccidentsSAP = accidents;

    commit("setAccidentsSAP", siteAccidentsSAP);
    commit("setFiltersMutationSAP", editedPayload);
    commit("setPagination", pagination);
  } catch (error) {
    console.error(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingFetchingAccidentsState", false);
  }
};

export const downloadFlyerTxtAction = async ({ commit }, payload) => {
  const filtersArr = [];
  payload.forEach((value) => {
    filtersArr.push(`accidentIds=${value}`);
  });
  const queryString = filtersArr.length > 0 ? "?" + filtersArr.join("&") : "";
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const resp = await axidentApi.get(`/hr/extraction${queryString}`);
    commit("updateDownloadedSAPAccidents", resp.data);
    return resp;
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
    return error;
  } finally {
    commit("setLoadingState", false);
  }
};

export const downloadFlyerCsvAction = async ({ commit }, payload) => {
  const filtersArr = [];
  payload.forEach((value) => {
    filtersArr.push(`accidentIds=${value}`);
  });
  const queryString = filtersArr.length > 0 ? "?" + filtersArr.join("&") : "";
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const resp = await axidentApi.get(
      `/hr/prevention/extraction${queryString}`
    );
    commit("updateDownloadedSAPAccidents", resp.data);
    return resp;
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
    return error;
  } finally {
    commit("setLoadingState", false);
  }
};

export const markAsManagedSAP = async ({ commit }, payload) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const resp = await axidentApi.patch("/hr/markAsManaged", payload);
    return resp;
  } catch (error) {
    console.error(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
    return error;
  } finally {
    commit("setLoadingState", false);
  }
};

export const markAsReferredToSS = async ({ commit }, payload) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const resp = await axidentApi.patch("/hr/markAsReferredSS", payload);
    return resp;
  } catch (error) {
    console.error(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
    return error;
  } finally {
    commit("setLoadingState", false);
  }
};

export const markAsClosed = async ({ commit }, payload) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const resp = await axidentApi.patch("/hr/markAsClosed", payload);
    return resp;
  } catch (error) {
    console.error(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
    return error;
  } finally {
    commit("setLoadingState", false);
  }
};

export const deleteAccidents = async ({ commit }, payload) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const resp = await axidentApi.patch("/hr/delete", payload);
    if (resp.status === 200) commit("REMOVE_ITEMS", payload);
    return resp;
  } catch (error) {
    console.error(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const sendToSAPAction = async ({ commit }, payload) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const resp = await axidentApi.post(
      "/accidentsSAP/sendToSapAction",
      payload
    );
    return resp.data;
  } catch (error) {
    console.error(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};
