// export const myAction = async ({commit}) => {}
import axidentApi from "../../api/axidentApi";
import { handleTokenExpired } from "../../helpers/handleTokenExpired";

export const getUsers = async (
  { commit },
  selectedPage = 0,
  pageSize = 1000
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: users, pagination },
    } = await axidentApi.get(
      `/users?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setUsers", users);
    commit("setPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const getUser = async ({ commit }, id) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const { data } = await axidentApi.get(`/users/${id}`);
    commit("setUser", data);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const updateRole = async ({ commit }, { userId, newRoleId }) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const { data } = await axidentApi.patch(`/users/${userId}/roles`, {
      role: {
        id: newRoleId,
      },
    });

    // Update the user role
    commit("updateUserRole", data.role.id);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const updateUser = async ({ commit, state }, payload) => {
  const { id, formData } = payload;
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const { data } = await axidentApi.patch(`/users/${id}`, formData);

    // Create new array with the updated values
    const updatedUsers = state.users.map((user) => {
      if (user.id === data.id) {
        return data;
      }
      return user;
    });
    // Commit the updated state to vuex to update the view
    commit("setUsers", updatedUsers);
    commit("setUser", data);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};
