export const setAccidentsSAP = (state, accidentsSAP) => {
  state.accidentsSAP = accidentsSAP;
};

export const setFiltersMutationSAP = (state, searchFormFilter) => {
  state.searchFormFilter = searchFormFilter;
};

export const setPagination = (state, pagination) => {
  state.pagination = pagination;
};

export const setErrorMessage = (state, message) => {
  state.errorMsg = message;
};

export const setLoadingState = (state, value) => {
  state.isLoading = value;
};

export const setLoadingFetchingAccidentsState = (state, value) => {
  state.isLoadingFetchingAccidents = value;
};

export const updateDownloadedSAPAccidents = (state, downloadedSAPAccidents) => {
  state.downloadedSAPAccidents = downloadedSAPAccidents;
};

export const REMOVE_ITEMS = (state, payload) => {
  for (let i = 0; i < payload.length; i++) {
    const index = state.accidentsSAP.finIndex((i) => i.id === payload[i].id);
    if (index > -1) state.accidentsSAP.splice(index, 1);
  }
};
