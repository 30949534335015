// export const myAction = async ({commit}) => {}
import axidentApi from "../../api/axidentApi";
import { handleTokenExpired } from "../../helpers/handleTokenExpired";

export const getCenters = async (
  { commit },
  { selectedPage = 0, pageSize = 1000 }
) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const {
      data: { data: centers, pagination },
    } = await axidentApi.get(
      `/insurance-centers?selectedPage=${selectedPage}&pageSize=${pageSize}`
    );
    commit("setCenters", centers);
    commit("setPagination", pagination);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const addNewCenter = async ({ commit }, center) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    await axidentApi.post(`/insurance-centers`, center);
    commit("addNewCenter", center);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const getCenter = async ({ commit }, id) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const { data } = await axidentApi.get(`/insurance-centers/${id}`);
    commit("setCenter", data);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};

export const updateCenter = async ({ commit }, { id, payload }) => {
  try {
    commit("setLoadingState", true);
    commit("setErrorMessage", "");
    const { data } = await axidentApi.patch(
      `/insurance-centers/${id}`,
      payload
    );
    commit("setCenter", data);
    commit("updateCenter", data);
  } catch (error) {
    console.log(error);
    commit("setErrorMessage", error);
    handleTokenExpired(error);
  } finally {
    commit("setLoadingState", false);
  }
};
