// export const myGetter = (state) => { return state.something }

import { accidentStatus } from "@/enums";

export const getAccidents = (state) =>
  state.accidents.sort(
    (a, b) => new Date(b.occurrenceDate) - new Date(a.occurrenceDate)
  );

export const getAccident = (state) => state.accident;

export const filteredAccidents = (state) => (searchTerm, keys) => {
  return state.accidents
    .filter((accident) =>
      keys.some((key) =>
        String(accident[key])
          .toLowerCase()
          .includes(searchTerm.trim().toLowerCase())
      )
    )
    .sort((a, b) => new Date(b.occurrenceDate) - new Date(a.occurrenceDate));
};

export const getAccidentsIds = (state) => {
  return state.accidents.map((accident) => accident.id);
};

export const getAccidentsSyncIds = (state) => {
  const syncAccidents = state.accidents.filter((accident) =>
    [
      accidentStatus.sent,
      accidentStatus.injuriedLicenseInsurance,
      accidentStatus.relapse,
    ].includes(accident.status)
  );

  return syncAccidents.map((accident) => accident.id);
};

export const getAccidentById = (state) => (id) => {
  return state.accidents.find((accident) => accident.id === id);
};

export const getAffectedUser = (state) => state.affectedUser;

export const getPagination = (state) => state.pagination;

export const getErrorCode = (state) => state.errorMsg?.response?.data?.code;

export const getErrorMessage = (state) =>
  state.errorMsg?.response?.data?.description;

export const getLoadingState = (state) => state.isLoading;
