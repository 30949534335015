// export const myMutation = (state) => { do something }

export const setCountriesFilter = (state, countries) => {
  state.countries = countries;
};

export const setContractsFilter = (state, contracts) => {
  state.contracts = contracts;
};

export const setSenioritiesFilter = (state, seniorities) => {
  state.seniorities = seniorities;
};

export const setDocumentsFilter = (state, documents) => {
  state.documents = documents;
};

// eslint-disable-next-line
export const setSearchTypesFilters = (state, searchTypes) => {
  // state.searchTypes = searchTypes;
};

export const setAccidentPlaces = (state, places) => {
  state.places = places;
};

export const setUsualWorkCenterType = (state, usualWorkCenterType) => {
  state.usualWorkCenterType = usualWorkCenterType;
};

export const setUsualWorkCenterZone = (state, usualWorkCenterZone) => {
  state.usualWorkCenterZone = usualWorkCenterZone;
};

export const setSeverities = (state, severities) => {
  state.severities = severities;
};

export const setInItinereTypes = (state, inItinereOpts) => {
  state.inItinereOpts = inItinereOpts;
};

export const setWorkedHours = (state, workedHours) => {
  state.workedHours = workedHours;
};

export const setActivities = (state, activities) => {
  state.activities = activities;
};

export const setCauses = (state, causes) => {
  state.causes = causes;
};

export const setWorkTypes = (state, workTypes) => {
  state.workTypes = workTypes;
};

export const setBasicCauses = (state, basicCauses) => {
  state.basicCauses = basicCauses;
};

export const setAutonomousCommunities = (state, autonomousCommunities) => {
  state.autonomousCommunities = autonomousCommunities;
};

export const setRrhhRegions = (state, regions) => {
  state.regions = regions;
};

export const setSiteAdditionalInfo = (state, additionalInfo) => {
  state.siteAdditionalInfo = additionalInfo;
};

export const setPagination = (state, pagination) => {
  state.pagination = pagination;
};

export const setErrorMessage = (state, message) => {
  state.errorMsg = message;
};

export const setLoadingState = (state, value) => {
  state.isLoading = value;
};

export const addNewRegion = (state, region) => {
  state.regions = [region, ...state.regions];
};

export const setMedicalReportTypes = (state, medicalReportTypes) => {
  state.medicalReportTypes = medicalReportTypes;
};

export const setMedicalReportCauseTypes = (state, medicalReportCauseTypes) => {
  state.medicalReportCauseTypes = medicalReportCauseTypes;
};

export const setMedicalReportForecastTypes = (
  state,
  medicalReportForecastTypes
) => {
  state.medicalReportForecastTypes = medicalReportForecastTypes;
};

export const setMedicalReportContactWayTypes = (
  state,
  medicalReportContactWayTypes
) => {
  state.medicalReportContactWayTypes = medicalReportContactWayTypes;
};

export const setMedicalReportInjuriedPartTypes = (
  state,
  medicalReportInjuriedPartTypes
) => {
  state.medicalReportInjuriedPartTypes = medicalReportInjuriedPartTypes;
};

export const setMedicalReportStandardInjuryDescriptionTypes = (
  state,
  medicalReportStandardInjuryDescriptionTypes
) => {
  state.medicalReportStandardInjuryDescriptionTypes =
    medicalReportStandardInjuryDescriptionTypes;
};
