export default () => ({
  accidents: [],
  // accidents: [
  //   {
  //     actionPlan: null,
  //     affectedUser: {
  //       name: "FOWLER",
  //       id: "8a6287ea-790a-a76f-0179-0dbc99911e1e",
  //       firstSurname: "ADSHEDE",
  //       customAdditonalInfo: {
  //         documentNumber: "54876321T",
  //         documentType: "NIF",
  //         hrId: "4168577",
  //         lastSurname: "MCCRONE",
  //         nationalSecurityNumber: "sd",
  //       },
  //     },
  //     cause: {
  //       id: "08",
  //       label: "Cargo Handling",
  //     },
  //     collaboratorSignature: {
  //       cannotSign: null,
  //       data: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAyc",
  //     },
  //     description: "",
  //     documentSent: {
  //       data: "data:application/pdf;base64,JVBERi0xLjMKJbrfrOA",
  //     },
  //     doingActivity: {
  //       id: "02",
  //       label: "Manual Pallet Truck",
  //     },
  //     emailDocumentSent: true,
  //     emailNotificationSent: null,
  //     emergencyBossSignature: {
  //       data: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAyc",
  //     },
  //     externalSite: null,
  //     hasInjuried: true,
  //     hourRange: {
  //       id: "0400_0500",
  //       label: "04:00 - 05:00",
  //     },
  //     id: "0a742e27-0ca7-4d82-931f-9fa643a2f5c3",
  //     inItineraryType: null,
  //     insuranceCenter: {
  //       id: "0301",
  //       name: "GC ALICANTE",
  //     },
  //     occurenceAddress: null,
  //     occurrenceDate: "2023-06-18",
  //     place: {
  //       id: "02",
  //       label: "Displacement during your working day (displacement on mission)",
  //     },
  //     registrationDate: "2023-07-14",
  //     severityType: {
  //       is: "SLIGHT",
  //       label: "Slight (without/with sick leave)",
  //     },
  //     site: {
  //       address: {
  //         city: "FINESTRAT",
  //         complement: null,
  //         country: {
  //           id: "ES",
  //           name: "Spain",
  //         },
  //         street: "0 AVENIDA PAIS",
  //         zipCode: "03509",
  //       },
  //       customAdditonalInfo: {},
  //       enabled: null,
  //       id: "1953",
  //       insuranceCenter: [],
  //       name: "FINESTRAT",
  //       phone: "0",
  //     },
  //     status: "RETURNED",
  //     type: "ACCIDENT",
  //     usualWorkCenter: {
  //       type: null,
  //       zone: null,
  //     },
  //     witnesses: [],
  //     workedHours: {
  //       id: "04",
  //       label: "4 Hours",
  //     },
  //   },
  //   {
  //     actionPlan: null,
  //     affectedUser: {
  //       name: "AAAAAA",
  //       id: "8a6287ea-790a-a76f-0179-0dbc99911e1e",
  //       firstSurname: "ADSHEDE",
  //       customAdditonalInfo: {
  //         documentNumber: "54876321T",
  //         documentType: "NIF",
  //         hrId: "4168577",
  //         lastSurname: "MCCRONE",
  //         nationalSecurityNumber: "sd",
  //       },
  //     },
  //     cause: {
  //       id: "08",
  //       label: "Cargo Handling",
  //     },
  //     collaboratorSignature: {
  //       cannotSign: null,
  //       data: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAyc",
  //     },
  //     description: "ewweqweqeqweqw",
  //     documentSent: {
  //       data: "data:application/pdf;base64,JVBERi0xLjMKJbrfrOA",
  //     },
  //     doingActivity: {
  //       id: "02",
  //       label: "Manual Pallet Truck",
  //     },
  //     emailDocumentSent: true,
  //     emailNotificationSent: null,
  //     emergencyBossSignature: {
  //       data: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAyc",
  //     },
  //     externalSite: null,
  //     hasInjuried: true,
  //     hourRange: {
  //       id: "0400_0500",
  //       label: "04:00 - 05:00",
  //     },
  //     id: "0a742e27-0ca7-4d82-931f-9fa643a2f5aa",
  //     inItineraryType: null,
  //     insuranceCenter: {
  //       id: "0301",
  //       name: "GC ALICANTE",
  //     },
  //     occurenceAddress: null,
  //     occurrenceDate: "2023-06-18",
  //     place: {
  //       id: "02",
  //       label: "Displacement during your working day (displacement on mission)",
  //     },
  //     registrationDate: "2023-07-14",
  //     severityType: {
  //       is: "SLIGHT",
  //       label: "Slight (without/with sick leave)",
  //     },
  //     site: {
  //       address: {
  //         city: "FINESTRAT",
  //         complement: null,
  //         country: {
  //           id: "ES",
  //           name: "Spain",
  //         },
  //         street: "0 AVENIDA PAIS",
  //         zipCode: "03509",
  //       },
  //       customAdditonalInfo: {},
  //       enabled: null,
  //       id: "1953",
  //       insuranceCenter: [],
  //       name: "FINESTRAT",
  //       phone: "0",
  //     },
  //     status: "SENT",
  //     type: "ACCIDENT",
  //     usualWorkCenter: {
  //       type: null,
  //       zone: null,
  //     },
  //     witnesses: [],
  //     workedHours: {
  //       id: "04",
  //       label: "4 Hours",
  //     },
  //   },
  //   {
  //     actionPlan: null,
  //     affectedUser: {
  //       name: "CCCCCC",
  //       id: "8a6287ea-790a-a76f-0179-0dbc99911e1e",
  //       firstSurname: "ADSHEDE",
  //       customAdditonalInfo: {
  //         documentNumber: "54876321T",
  //         documentType: "NIF",
  //         hrId: "4168577",
  //         lastSurname: "MCCRONE",
  //         nationalSecurityNumber: "sd",
  //       },
  //     },
  //     cause: {
  //       id: "08",
  //       label: "Cargo Handling",
  //     },
  //     collaboratorSignature: {
  //       cannotSign: null,
  //       data: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAyc",
  //     },
  //     description: "ewweqweqeqweqw",
  //     documentSent: {
  //       data: "data:application/pdf;base64,JVBERi0xLjMKJbrfrOA",
  //     },
  //     doingActivity: {
  //       id: "02",
  //       label: "Manual Pallet Truck",
  //     },
  //     emailDocumentSent: true,
  //     emailNotificationSent: null,
  //     emergencyBossSignature: {
  //       data: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAyc",
  //     },
  //     externalSite: null,
  //     hasInjuried: true,
  //     hourRange: {
  //       id: "0400_0500",
  //       label: "04:00 - 05:00",
  //     },
  //     id: "0a742e27-0ca7-4d82-931f-9fa643a2f5bb",
  //     inItineraryType: null,
  //     insuranceCenter: {
  //       id: "0301",
  //       name: "GC ALICANTE",
  //     },
  //     occurenceAddress: null,
  //     occurrenceDate: "2023-06-18",
  //     place: {
  //       id: "02",
  //       label: "Displacement during your working day (displacement on mission)",
  //     },
  //     registrationDate: "2023-07-14",
  //     severityType: {
  //       is: "SLIGHT",
  //       label: "Slight (without/with sick leave)",
  //     },
  //     site: {
  //       address: {
  //         city: "FINESTRAT",
  //         complement: null,
  //         country: {
  //           id: "ES",
  //           name: "Spain",
  //         },
  //         street: "0 AVENIDA PAIS",
  //         zipCode: "03509",
  //       },
  //       customAdditonalInfo: {},
  //       enabled: null,
  //       id: "1953",
  //       insuranceCenter: [],
  //       name: "FINESTRAT",
  //       phone: "0",
  //     },
  //     status: "SENT",
  //     type: "ACCIDENT",
  //     usualWorkCenter: {
  //       type: null,
  //       zone: null,
  //     },
  //     witnesses: [],
  //     workedHours: {
  //       id: "04",
  //       label: "4 Hours",
  //     },
  //   },
  // ],
  accident: undefined,
  affectedUser: {},
  pagination: {},
  errorMsg: "",
  isLoading: false,
});
