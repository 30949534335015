// export const myMutation = (state) => { do something }

import { saveState } from "../helpers/authHelpers";

export const loginUser = (state, { access_token, id_token }) => {
  if (id_token) {
    saveState("idToken", id_token);
    state.idToken = id_token;
  }
  if (access_token) {
    saveState("accessToken", access_token);
    state.accessToken = access_token;
  }
  state.enabled = true;
  state.sessionExpired = false;
  state.loginError = false;
};

export const setCurrentUser = (state, user) => {
  state.currentUser = user;
  state.enabled = true;
  state.sessionExpired = false;
  state.loginError = false;
};

export const updateCurrentUser = (state, data) => {
  state.currentUser.address = data.address;
  state.currentUser.birthDate = data.birthDate;
  state.currentUser.contract = data.contract;
  state.currentUser.manager = data.manager;
  state.currentUser.phone = data.phone;
  state.currentUser.site = data.site;
  state.currentUser.team = data.team;
};

export const setFedIdUser = (state, fedIdUser) => {
  state.fedIdUser = fedIdUser;
};

export const setUserAsDisabled = (state) => {
  state.enabled = false;
};

export const sessionExpired = (state) => {
  state.sessionExpired = true;
};

export const setLoginError = (state) => {
  state.loginError = true;
};

export const setErrorMessage = (state, message) => {
  state.errorMsg = message;
};

export const setLoadingState = (state, value) => {
  state.isLoading = value;
};

export const logout = (state) => {
  state.currentUser = null;
  state.accessToken = null;
  state.idToken = null;
  state.fedIdUser = null;
  state.enabled = null;

  localStorage.removeItem("accessToken");
  localStorage.removeItem("idToken");
  localStorage.removeItem("role");

  window.location.replace(
    `${process.env.VUE_APP_BASE_URL}?response_type=code&redirect_uri=${process.env.VUE_APP_REDIRECT_URI}&scope=openid%20profile%20email&client_id=${process.env.VUE_APP_CLIENT_ID}`
  );
};
