import axios from "axios";
import useAuth from "../composables/useAuth";
import router from "@/router";

const authApi = axios.create({
  baseURL: process.env.VUE_APP_AXIDENT_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

authApi.interceptors.request.use((config) => {
  const { authToken } = useAuth();
  config.headers = { Authorization: `Bearer ${authToken.value}` };
  return config;
});

authApi.interceptors.response.use(
  (response) => {
    return response;
  },

  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      router.push("/");
    }
    return Promise.reject(error);
  }
);

export default authApi;
